import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { FormattedPlural } from "react-intl";
import { take } from "lodash";

import "./ProductSummary.scss";

const ProductSummary = ({ booking = {}, ficheProduit = {} }) => {
	const geographyFirstLevel =
		ficheProduit?.geography?.levels[ficheProduit?.geography?.levels?.length - 1]?.label;
	const geographySecondLevel = take(
		ficheProduit?.geography?.levels,
		ficheProduit?.geography?.levels?.length - 1
	)
		?.map(geo => geo.label)
		?.join(", ");
	const hasLocationInfo = ficheProduit?.geography || ficheProduit?.region || ficheProduit?.resort;
	const accommodationNights = booking?.accommodationNights || booking?.duration?.value;

	return (
		<div data-testid="product-summary" className="product-summary">
			{hasLocationInfo && (
				<Typography
					variant={TYPOGRAPHY_VARIANTS.REGULAR}
					className="product-summary__label"
					data-testid="product-summary-location"
				>
					{ficheProduit?.geography ? (
						<>
							{geographyFirstLevel && geographyFirstLevel}
							{geographyFirstLevel && geographySecondLevel && ", "}
							{geographySecondLevel && geographySecondLevel}
						</>
					) : (
						<>
							{ficheProduit.region}, {ficheProduit.resort}
						</>
					)}
				</Typography>
			)}
			{accommodationNights && accommodationNights > 0 && (
				<Typography
					variant={TYPOGRAPHY_VARIANTS.REGULAR}
					className="product-summary__label"
					data-testid="product-summary-accommodation-nights"
				>
					<FormattedPlural
						data-testid="night-count"
						value={accommodationNights}
						one={
							<FormattedMessage
								id="general.duration.singular"
								values={{
									duration: accommodationNights,
								}}
							>
								{accommodationNights}
							</FormattedMessage>
						}
						other={
							<FormattedMessage
								id="general.duration.plural"
								values={{
									duration: accommodationNights,
								}}
							>
								{accommodationNights}
							</FormattedMessage>
						}
					/>
				</Typography>
			)}
		</div>
	);
};

ProductSummary.propTypes = {
	booking: PropTypes.object,
	ficheProduit: PropTypes.object,
};

export default memo(ProductSummary);
