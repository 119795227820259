import { connect } from "react-redux";

import ProductSummary from "app/pages/.shared/ProductSummary/ProductSummary";

const mapStateToProps = state => ({
	booking: state.booking,
	ficheProduit: state.ficheProduit,
});

export default connect(mapStateToProps)(ProductSummary);
